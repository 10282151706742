@font-face {
  font-family: avenir;
  src: url('./assets/fonts/Avenir.ttc');
}

@font-face {
  font-family: provicali;
  src: url('./assets/fonts/Provicali.otf');
}

* {
  font-family: avenir;
}

.overflow-hidden {
  overflow: hidden;
  height: 100vh;
}

.input-range {
  position: relative;
}

.input-range__label-container {
  display: none;
}

.input-range__track {
  background: #eee;
  border-radius: .3rem;
  cursor: pointer;
  display: block;
}

input-range__track input-range__track--background {
  left: 0;
  margin-top: -.15rem;
  position: absolute;
  right: 0;
  top: 50%;
}

.input-range__track .input-range__track--active {
  background-color: rgba(244,118,33,0.56);
  height: 2px;
  position: relative;
}

.input-range__slider {
  height: 10px !important;
  width: 10px !important;
  background-color: #22376E !important;
  box-sizing: border-box !important;
  border-radius: 50%;
  margin-top: -6px;
}